import React from 'react'
import Img from 'gatsby-image'

import RightChevron from '../../components/SVG/RightChevron'

const linkResolver = (doc) => {
  if (doc.type === 'case_studies_article') {
    return `/case-studies/${doc.uid}`
  } else {
    return `/${doc.uid}`
  }
}

const Products = ({ data }) => {
  return (
    <div className="offset-container-left flex -ml-8 pb-8 my-4 lg:mt-0 lg:mb-16 overflow-x-scroll overflow-y-visible hide-scroll-bar smooth-scroll">
      {data.items.map((item, index) => {
        return (
          <div
            key={index}
            className="w-3/4 sm:w-1/2 md:w-1/3 lg:w-1/4 ml-8 flex-none overflow-visible relative z-0 flex flex-col"
          >
            <div className="bg-shadow" />
            <div className="bg-gradient-grey-light">
              {item.product_image.url && (
                <Img
                  className="mx-6"
                  fluid={item.product_image.localFile.childImageSharp.fluid}
                  alt={item.product_image.alt}
                />
              )}
            </div>
            <div className="bg-grey-light p-5 h-full">
              {item.product_heading && (
                <a
                  className="button-group flex justify-center mb-5"
                  href={item.product_download.uid ? linkResolver(item.product_download) : item.product_download.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5 className="inline-block font-semibold">
                    {item.product_heading}
                  </h5>
                </a>
              )}
              {item.product_download.url && (
                <a
                  className="w-full inline-block button button--white px-4 py-5 mb-0 font-semibold border-blue border"
                  href={item.product_download.uid ? linkResolver(item.product_download) : item.product_download.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="button__inner text-xs md:text-base">
                    Learn More
                    <div className="ml-4">
                      <RightChevron />
                    </div>
                  </div>
                </a>
              )}
            </div>
          </div>
        )
      })}
      <div className="pl-8" />
    </div>
  )
}

export default Products
